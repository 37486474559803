import React from 'react';
import cx from 'classnames';

const searchicon = ({
    fontSize = 25, className = '', iconContent = '', ...other
}) => (
    <>
        <span className={cx('search-icon text-neutral-black', className)} {...other} />
        <style jsx>
            {`
                        .search-icon:before {
                            display: block;
                            content: ${iconContent || '"\\e955"'};
                            font-family: var(--icons-urbanicon-fossil-theme);
                            font-size: ${fontSize}px;
                            line-height: ${fontSize}px;
                            width: ${fontSize}px;
                            height: ${fontSize}px;
                        }
                        
                    `}
        </style>
    </>
);

export default searchicon;

/* eslint-disable no-useless-escape */
import React from 'react';

const burgermenuicon = () => (
    <>
        <span className="burgermenu-icon text-neutral-black" />
        <style jsx>
            {`
                        .burgermenu-icon:before {
                            display: block;
                            content: "\e939";
                            font-family: var(--icons-urbanicon-fossil-theme);
                            font-size: 1.125rem;
                            line-height: 25px;
                            width: 25px;
                            height: 25px;
                        }
                        
                    `}
        </style>
    </>
);

export default burgermenuicon;

/* eslint-disable no-useless-escape */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import cx from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { useEffect } from 'react';
import Image from '@common_image';
import Typography from '@common_typography';
import config from '@config';

import Button from '@common_button';
import { useRouter } from 'next/router';

// import Bars3Icon from '@heroicons/react/24/solid/Bars3Icon';
// import MagnifyingGlassIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';

import Drawer from '@common_drawer';
import Tabs from '@common_tabs';
import useMediaQuery from '@core/hooks/useMediaQuery';
import BurgerMenuIcon from '@core_modules/theme/components/header/components/v1/adaptive/plugin/burgermenuicon';
import SearchIcon from '@core_modules/theme/components/header/components/v1/adaptive/plugin/urbanicon';

const Autocomplete = dynamic(() => import('@core_modules/theme/components/header/components/autocomplete'), { ssr: true });
const Menu = dynamic(() => import('@core_modules/theme/components/header/components/v1/mcategory'), { ssr: true });
// const ProductCompareIcon = dynamic(() => import('@core_modules/catalog/plugins/ProductCompare'), { ssr: true });
const ShoppingBagIcon = dynamic(() => import('@plugin_shoppingbag'), { ssr: true });
// const NotificationBell = dynamic(() => import('@plugin_notificationbell'), { ssr: true });
// const SwitcherCurrency = dynamic(() => import('@common_currency'), { ssr: false });
// const SwitcherLanguage = dynamic(() => import('@common_language'), { ssr: false });
const UserInfo = dynamic(() => import('@core_modules/theme/components/header/components/v1/adaptive/plugin/userinfo'), { ssr: true });
const WishlistIcon = dynamic(() => import('@core_modules/theme/components/header/components/v1/adaptive/plugin/wishlist'), { ssr: true });
const CompareIcon = dynamic(() => import('@core_modules/theme/components/header/components/v1/adaptive/plugin/compare'), { ssr: true });

// const InstallDesktop = dynamic(() => import('@core_modules/theme/components/customPWAInstall/desktop'), { ssr: true });
const BurgerMenuCategories = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/categories'), { ssr: false });
const BurgerMenuAccount = dynamic(() => import('@core_modules/theme/components/header/components/burgermenu/account'), { ssr: false });

const initialOpenState = {
    isAccountOpen: false,
    isCartOpen: false,
};

const openReducer = (state, action) => {
    switch (action.type) {
        case 'OPEN_ACCOUNT':
            return { isCartOpen: false, isAccountOpen: true };
        case 'OPEN_CART':
            return { isCartOpen: true, isAccountOpen: false };
        case 'CLOSE_ACCOUNT':
            return { ...state, isAccountOpen: false };
        case 'CLOSE_CART':
            return { ...state, isCartOpen: false };
        default:
            return state;
    }
};

const DesktopHeader = (props) => {
    const {
        //
        t,
        storeConfig,
        isLogin,
        customer,
        setValue,
        handleSearch,
        dataMenu,
        loadingMenu,
        handleLogout,
        deviceWidth,
        cmsMenu,
        ...other
    } = props;
    // eslint-disable-next-line no-unused-vars
    const { modules } = config;
    const { isDesktop, isMobile, isTablet } = useMediaQuery();

    const logoDimensions = {
        width: storeConfig?.logo_width || (isDesktop ? 120 : 74),
        height: storeConfig?.logo_height || (isDesktop ? 52 : 34),
    };
    const logoAdditionalProps = {};
    if (!isMobile && !isTablet) {
        logoAdditionalProps.styleContainer = {
            width: `${logoDimensions.width}px`,
            height: `${logoDimensions.height}px`,
            paddingTop: 0,
        };
    }

    const [open, setOpen] = React.useState(false);
    const [openCart, setOpenCart] = React.useState(false);
    const [openState, dispatch] = React.useReducer(openReducer, initialOpenState);
    const [openBurgerMenu, setOpenBurgerMenu] = React.useState(false);
    const [localOpen, setLocalOpen] = React.useState(false);
    const [isSearchShown, setIsSearchShown] = React.useState(false);

    const handleClose = () => {
        setLocalOpen(false);
        setTimeout(() => {
            setOpenBurgerMenu(false);
        }, 500);
    };

    // Close drawer menu after move to other page
    const router = useRouter();
    useEffect(() => {
        if (openBurgerMenu && !isDesktop) {
            setOpenBurgerMenu(false);
        }
    }, [router]);

    useEffect(() => {
        setLocalOpen(openBurgerMenu);
    }, [openBurgerMenu]);

    const filteredData = dataMenu?.categories?.items[0]?.children.filter((item) => item.include_in_menu !== 0);
    const burgerMenuData = [
        {
            title: 'Menu',
            content: dataMenu && <BurgerMenuCategories data={filteredData} cmsMenu={cmsMenu} />,
            type: 'react-component',
        },
        {
            title: 'Account',
            content: <BurgerMenuAccount isLogin={isLogin} handleLogout={handleLogout} {...other} />,
            type: 'react-component',
        },
    ];

    const PopoverContent = () => {
        return (
            <div
                onMouseLeave={() => dispatch({ type: 'CLOSE_ACCOUNT' })}
                className={cx(
                    'py-[20px]', 'px-0', 'min-h-fit', 'max-w-[308px]', '!rounded-[5px]', 'popover-active',
                    'w-[208px]',
                )}
            >
                <ul className={cx('my-account-list__wrapper')}>
                    <li
                        key={0}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                    >
                        <Link href="/customer/account">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:myAccount')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={1}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                    >
                        <Link href="/sales/order/history">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:orderHistory')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={2}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                    >
                        <Link href="/wishlist">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:wishlist')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={3}
                        className={cx(
                        'my-account-list__item',
                        'py-[6px]',
                        'px-[30px]',
                        'text-left',
                        'hover:cursor-pointer',
                        'hover:bg-primary-50',
                    )}
                    >
                        <Link href="/catalog/product_compare">
                            <Typography
                                className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                            >
                                {t('common:header:compareProduct')}
                            </Typography>
                        </Link>
                    </li>
                    <li
                        key={4}
                        className={cx(
                            'my-account-list__item',
                            'py-[6px]',
                            'px-[30px]',
                            'text-left',
                            'hover:cursor-pointer',
                            'hover:bg-primary-50',
                        )}
                        onClick={() => handleLogout()}
                    >
                        <Typography
                            className={cx(
                                    'currency-list__text !text-neutral-black !text-[15px] font-medium leading-[22px]',
                                )}
                        >
                            {t('common:header:logout')}
                        </Typography>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div className={cx('desktop-header', 'transition-transform', 'delay-300', 'duration-500', 'ease-in-out', 'shadow-base')}>
            {/* <div
                id="top-header"
                className={cx('top-header', 'tablet:border-b', 'tablet:border-b-neutral-200', 'py-[1px]', 'min-h-[40px]', 'mobile:max-tablet:hidden')}
            >
                <div
                    id="top-header__content"
                    className={cx(
                        'top-header__content',
                        'grid grid-cols-[75%_25%]',
                        'tablet:max-w-[768px] desktop:max-w-[1280px]',
                        'm-[0_auto]',
                        'desktop:px-10 tablet:px-6 mobile:px-4',
                    )}
                >
                    <InstallDesktop t={t} />
                    {!isMobile ? (
                        <div
                            className={cx(
                                'top-header__content--currency-language-changer-menu',
                                'flex',
                                'flex-wrap',
                                'flex-column',
                                'justify-end',
                                'gap-x-4',
                            )}
                        >
                            <SwitcherCurrency {...props} />
                            <SwitcherLanguage {...props} />
                        </div>
                    ) : null}
                </div>
            </div> */}
            <div className={cx('middle-header', 'tablet:pt-6', 'tablet:pb-[30px]', 'desktop:pt-4', 'desktop:pb-0')}>
                <div
                    className={cx(
                        'middle-header__wrapper',
                        'm-[0_auto]',
                        'flex',
                        'flex-row',
                        'desktop:justify-between',
                        'tablet:max-w-full',
                        'tablet:gap-x-0',
                        'tablet:px-4',
                        'mobile:px-[15px]',
                        'tablet:px-2.5',
                        'desktop:gap-x-10',
                        'desktop:max-w-screen-xl',
                        'desktop:px-2.5',
                        'mobile:w-full',
                        // 'mobile:max-tablet:grid-cols-[4fr_8fr_4fr]',
                        'mobile:max-tablet:max-w-[100%]',
                        'mobile:max-tablet:items-center',
                        'mobile:h-[51px]',
                        'tablet:h-auto',
                    )}
                >
                    {!isDesktop ? (
                        <div className={cx(
                                'middle-header-tablet__burger-menu',
                                'desktop:hidden',
                                'mr-2.5',
                                'tablet:max-desktop:relative',
                                'tablet:max-desktop:left-[-1px]',
                            )}
                        >
                            <Button
                                className={cx(
                                    'my-2',
                                    '!p-0',
                                    '!mx-0',
                                    'hover:shadow-none',
                                    'focus:shadow-none',
                                    'active:shadow-none',
                                    'active:shadow-none',
                                    'tablet:!pl-[5px]',
                                    'mobile:!pl-0',
                                    'mobile:max-tablet:pt-[7px]',
                                    'mt-[6px]',
                                    'mobile:max-tablet:mt-2',
                                    'tablet:max-desktop:!pl-0',
                                    'tablet:max-desktop:mt-[3px]',
                                )}
                                onClick={() => {
                                    setOpenBurgerMenu(true);
                                }}
                                icon={<BurgerMenuIcon />}
                                iconProps={{ className: cx('text-neutral-700', 'w-[24px]', 'h-[24px]') }}
                                iconOnly
                                variant="tertiary"
                                classNameText={cx('!text-neutral-700')}
                                aria-label="burger-menu"
                            />
                            {dataMenu && openBurgerMenu && (
                                <Drawer
                                    open={localOpen}
                                    handleClose={handleClose}
                                    position="left"
                                    className={cx('mobile:max-tablet:w-[280px]', 'tablet:max-desktop:w-[396px]', 'desktop:w-[540px]', {
                                        'animate-hamburger-drawer-in': localOpen,
                                        'animate-hamburger-drawer-out': !localOpen,
                                    })}
                                    customButtonClose
                                    backdrop
                                >
                                    <Tabs
                                        data={burgerMenuData}
                                        tabHasContent
                                        tabWrapperClassName={cx('border-none')}
                                        tabTitleWrapperClassName={cx('grid', 'grid-cols-2')}
                                        tabTitleClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                                        tabTitleActiveClassName={cx('border-none', '!text-neutral-700', 'text-2md', 'font-semibold')}
                                        tabTitleListClassName={cx('bg-neutral-100')}
                                        tabTitleListActiveClassName={cx('bg-neutral-white', 'border-b-[1px]', 'border-b-neutral-400')}
                                        tabContentClassName={cx('!pt-0', 'h-full', 'overflow-y-auto', 'overflow-x-hidden')}
                                    />
                                </Drawer>
                            )}
                        </div>
                    ) : null}
                    <div
                        className={cx(
                            'middle-header__logo',
                            'desktop:basis-[44%]',
                            'desktop:w-[44%]',
                            'desktop:min-w-[200px]',
                            'desktop:h-[auto]',
                            'tablet:max-w-[160px]',
                            'desktop:max-w-[200px]',
                            // 'desktop:flex',
                            'items-center',
                            'cursor-pointer',
                            'mobile:max-tablet:h-[36px]',
                            'mobile:max-tablet:min-w-[130px]',
                            'mobile:max-tablet:mt-[16px]',
                            // 'mobile:max-tablet:m-[0_auto]',
                            'mobile:max-tablet:relative',
                            'mt-[6px]',
                            'mobile:max-tablet:self-center',
                            'tablet:max-desktop:min-w-[160px]',
                            'tablet:max-desktop:h-[36px]',
                            'tablet:max-desktop:relative',
                            'tablet:max-desktop:self-center',
                            'tablet:max-desktop:mt-[8px]',
                            'mobile:max-tablet:ml-[14px]',
                        )}
                    >
                        <Link href="/">
                            <Image
                                className="swift-header-middle__logo-link"
                                src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                alt={storeConfig.default_title}
                                {...logoDimensions}
                                {...logoAdditionalProps}
                                storeConfig={storeConfig}
                            />
                        </Link>
                    </div>
                    <div
                        className={cx(
                            'middle-header-right flex justify-between tablet:w-[70%]',
                            'desktop:w-[74%] items-center pl-5 gap-x-10 desktop:mt-[1px] h-[36px]',
                            'mobile:max-tablet:flex-auto',
                        )}
                    >
                        <div className={cx('middle-header__search', 'mobile:max-tablet:hidden', 'w-full')}>
                            <Autocomplete setValue={setValue} handleSearch={handleSearch} t={t} storeConfig={storeConfig} deviceWidth={deviceWidth} />
                        </div>
                        <div
                            className={cx(
                                'middle-header__statusicon',
                                // 'desktop:grid',
                                // 'desktop:grid-cols-[5fr_6fr]'
                                'flex',
                                'w-full',
                                'tablet:max-w-fit',
                                'justify-end',
                            )}
                        >
                            <div
                                className={cx(
                                'middle-header__statusicon__left-section',
                                'flex',
                                'flex-row',
                                'desktop:gap-x-10',
                                'mobile:gap-x-5',
                                'mobile:max-tablet:justify-end',
                                'items-center',
                            )}
                            >
                                {/* <div className={cx('swift-action-notification', 'mobile:max-tablet:hidden')}>
                                <NotificationBell withLink />
                            </div> */}
                                {/* {modules.productcompare.enabled && (
                                <div className={cx('swift-action-product-compare', 'mobile:max-desktop:hidden')}>
                                    <ProductCompareIcon withLink isLogin={isLogin} />
                                </div>
                            )} */}
                                <div className="search-icon tablet:hidden mobile:max-tablet:mr-[5px]">
                                    <Button
                                        className={cx(
                                        'mt-1',
                                        '!px-0',
                                        '!py-0',
                                        'hover:shadow-none',
                                        'focus:shadow-none',
                                        'active:shadow-none',
                                        'active:shadow-none',
                                    )}
                                        onClick={() => setIsSearchShown(!isSearchShown)}
                                        icon={<SearchIcon />}
                                        iconProps={{ className: cx('text-neutral-700', 'w-[20px]', 'h-[20px]') }}
                                        iconOnly
                                        variant="tertiary"
                                        classNameText={cx('!text-neutral-700')}
                                    />
                                </div>
                                <div
                                    className={cx(
                                    'middle-header__statusicon__right-section__wishlist',
                                    'hover:cursor-pointer',
                                    'flex',
                                    'justify-start',
                                    'group',
                                    'mobile:hidden',
                                    'tablet:block',
                                )}
                                >
                                    <CompareIcon />
                                </div>
                                <div
                                    className={cx(
                                    'middle-header__statusicon__right-section__wishlist',
                                    'hover:cursor-pointer',
                                    'flex',
                                    'justify-start',
                                    'group',
                                    'mobile:hidden',
                                    'tablet:block',
                                )}
                                >
                                    <WishlistIcon />
                                </div>
                                <div
                                    className={cx(
                                    'middle-header__statusicon__right-section__account',
                                    'hover:cursor-pointer',
                                    'flex',
                                    'justify-start',
                                    'group',
                                )}
                                >
                                    <UserInfo
                                        t={t}
                                        isLogin={isLogin}
                                        customer={customer}
                                        open={open}
                                        setOpen={setOpen}
                                        setOpenCart={setOpenCart}
                                        PopoverContent={PopoverContent}
                                        openState={openState}
                                        handleDispatch={dispatch}
                                        {...props}
                                    />
                                </div>
                                <div
                                    id="header-shoppingBag-icon"
                                    className={cx(
                                        'swift-action-shopping-bag relative tablet:h-[28px] mobile:max-tablet:h-[25px]',
                                        'tablet:max-desktop:pl-5 tablet:max-desktop:pr-3 mr-3',
                                        'mobile:max-tablet:mr-0',
                                        'tablet:max-desktop:mr-0',
                                        'tablet:max-desktop:pr-[4.5px]',
                                    )}
                                >
                                    <ShoppingBagIcon
                                        openCart={openCart}
                                        setOpenCart={setOpenCart}
                                        setOpenAccount={setOpen}
                                        openState={openState}
                                        handleDispatch={dispatch}
                                        withLink
                                        withLinkToCart
                                        storeConfig={storeConfig}
                                    />
                                </div>
                            </div>
                            {/* <div className={cx('middle-header__statusicon__right-section relative', 'mobile:max-desktop:hidden')}>
                            <span
                                className={cx(
                                    'border-l-[1.5px]',
                                    'border-l-neutral-200',
                                    'absolute',
                                    'left-0',
                                    'top-[50%]',
                                    'translate-y-[-50%]',
                                    'h-7',
                                )}
                            >
                                &nbsp;
                            </span>
                            <div
                                className={cx(
                                    'middle-header__statusicon__right-section__account',
                                    'pl-2',
                                    // 'mt-3',
                                    'hover:cursor-pointer',
                                    'flex',
                                    'justify-start',
                                    'group',
                                )}
                            >
                                <UserInfo t={t} isLogin={isLogin} customer={customer} open={open} setOpen={setOpen} PopoverContent={PopoverContent} />
                            </div>
                        </div> */}
                        </div>
                    </div>

                </div>
            </div>
            <div className={cx('bottom-header', 'tablet:max-w-[768px]', 'desktop:max-w-screen-xl', 'm-[3px_auto_0]', 'px-2.5', 'relative')}>
                <div className="flex flex-row menu-category mobile:max-desktop:hidden">
                    <div className="xs:basis-full menu-middle">
                        <nav className="swift-menu-wrapper" role="navigation">
                            {loadingMenu ? <></> : <Menu t={t} data={dataMenu} cmsMenu={cmsMenu} storeConfig={storeConfig} />}
                        </nav>
                    </div>
                </div>
                {isSearchShown && isMobile ? (
                    <div className={cx('bottom-header-mobile__search')}>
                        <Autocomplete setValue={setValue} handleSearch={handleSearch} t={t} storeConfig={storeConfig} />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default DesktopHeader;
